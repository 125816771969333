@import "./helper";

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/bootstrap";

@import "../argon_theme/scss/argon-design-system/variables";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

$primary: #3f46cf;
$secondary: #ff0000;
$tertiary: #BF0000;
$text_sec: #50c9ff;
html,body{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #263238;
}
div.main_public_layout{
	font-family: 'Poppins', sans-serif;
	color: #222c3e;
	min-height: 715px !important;
}


div.navTabs{
	@include block_ctrl();
	>ul{
		@include block_ctrl();
		>li{
			@include block_ctrl();
			padding: 7px 15px;
			text-align: center;
			>a{
				@include block_ctrl();
    			padding: 10px 0px;
    			background-color: $tertiary;
    			color: #FFFFFF;
    			font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   			    letter-spacing: 0px;
   			    border-bottom: 2px solid $tertiary;
    			@include ease(.15s);
    			&:before{
    				content: '';
    				display: inline-block;
    				position: absolute;
    				left: 7px;
    				top: 0px;
    				bottom: -2px;
    				margin: auto 0px;
    				width: 5px;
    				background-color: transparent;
    				pointer-events: none;
    				@include ease(.15s);
    			}
				&:hover{
				    letter-spacing: .5px;
				    border-color: #000000;
				    background-color: rgba($tertiary, .9);
				}
				&.active{
				    letter-spacing: .5px;
				    border-color: #000000;
				    &:before{
				    	background-color: #000000;
				    }
				}
			}
		}
	}
}

div.dropzone_{
    border-radius: 2px;
    border: 2px dashed #eaeaea;
    padding: 25px 0px;
    text-align: center;
}
.rootcard{
	width: 100%;
	.mediacard{
		height: 150px;
		.buttonCard{
    		display: inline-block;
    		position: relative;
    		float: right;
    		padding: 0px;
    		margin: 0px;
    		top: 5px;
    		right: 5px;
			.svgButtonCard{
				color: $tertiary;
			}
		}
	}
}

div.item_upload{
	@include block_ctrl();
	text-align: center;

	>div.img_prev{
		@include block_ctrl();
		width: 100%;
		height: 200px;
		border: 1px solid #DDDDDD;
		border-radius: 2px;
    	margin: 0px auto;
    	float: none;
		>img{
			display: inline-block;
			position: absolute;
    		height: auto;
    		width: auto;
    		max-width: 100%;
    		max-height: 100%;
    		margin: auto;
			top: 0px;
			bottom: 0px;
			left: 0px;
			right: 0px;
		}
	}
	>input{
		display: none;
	}
	>label{
		&.disabled{
			pointer-events: none;
		}
		>button{

		}
	}
}

ul.listSpecs{
	>li{
	    margin: 5px 0px;
		>svg{
			margin-right: 5px;
    		bottom: 2px;
    		position: relative;
		}
	}
}



.dialog_datepidker{
	.datepick_st{
	    z-index: 9;
		display: inline-block;
    	float: left;
    	width: 100%;
    	height: auto;
		.react-datepicker-wrapper {
		    width: 100%;
			input{
			    width: 100%;
			    height: 56px;
			    display: inline-block;
			    position: relative;
			    letter-spacing: inherit;
			    border: 1px solid rgba(0, 0, 0, 0.23);
			    border-radius: 4px;
			    padding: 18.5px 14px;
			}
		}
		div.react-datepicker__triangle {
		    margin-left: -20px !important;
		}
	}
	.MuiDialog-paperScrollPaper{
		overflow: auto;
		div.MuiDialogContent-root{
			overflow: hidden;
			>.MuiGrid-container {
			    display: inline-block;
			}
		}
	}
}

.calendar .months-container .month-container.month-3 {
    height: 225px;
}

p.error{
	display: block;
	position: relative;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
	color: #f44336;
}

div.item_slide_type{
	padding: 30px;
	>div{
		position: relative;
		height: 240px;
	    box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
	    border-radius: 5px;
    	overflow: hidden;
		>div{
			padding-right: 240px;
    		margin: 0px;
    		height: 100%;
		}
	}
	span{
		@include block_ctrl();
    	color: #424242;
    	font-size: 1.1rem;
    	font-weight: 700;
    	padding-bottom: 15px;
	}
	img{
		display: inline-block;
		position: absolute;
		right: 0px;
		top: 0px;
		bottom: 0px;
		width: 240px;
		height: 240px;
		margin: auto;
	}
}


a.btn_custom,button.btn_custom{
	border: 1px solid #DDDDDD;
	color: #FFFFFF;
	font-size: .9rem;
	&.btn_google{
		background-color: $google-plus;
	}
	&.btn_facebook{
		background-color: $facebook;
	}
	i.fa{
		font-size: 1.1rem;
		top: 0px;
    	position: relative;
	}
	&.desync{
		opacity: .55;
	}
	&.on_register{
		width: 50%;
		img{
			width: 25px;
			position: relative;
			margin-right: 7px;
			top: 0px;
		}
		&:hover{
			span{
				color: #FFFFFF;
			}
		}
	}
}

div.container_pagination{
	@include block_ctrl();
	text-align: right;
	padding: 30px 15px;
	>nav.MuiPagination-root{
		display: inline-block;
		position: relative;
		float: right;
		width: auto;
		>{
			ul{
				button.MuiPaginationItem-page{
					&.Mui-selected{
						background-color: $primary;
						color: #FFFFFF;
					}
				}
			}
		}
	}
}

div.absoluteLoader{
	@include absolute_center();
	background-color: #FFFFFF;
	z-index: 3;
	>div.spinner-border{
		@include absolute_center();
    	width: 50px;
    	height: 50px;
	}
}

.MuiContainer-root.MuiContainer-maxWidthSm{
    box-shadow: 0 3px 6px rgba(0,205,196,.30), 0 3px 6px rgba(0,205,196,.23);
    padding: 30px;
}

.MuiAppBar-colorPrimary{
	background-color: #e4e4e4 !important;
}

header.MuiAppBar-root{
	>div.MuiToolbar-root{
		text-align: center;
		>a{
			float: none;
	    	margin: 0px auto;
			>img{
    			width: 45px;
			}
		}
	}
}

.custom_list{
	div.MuiListItemIcon-root{
		min-width: auto !important;
		>svg{
			margin-right: 15px;
		}
	}
	>div.MuiButtonBase-root{
		>div.MuiListItemText-root{
			>span{
				font-weight: 600;
			}
		}
	}
	div.MuiCollapse-root{
		padding-left: 30px;
		div.MuiListItemText-root{
			>span{
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
}

.custom_error{
    color: #f44336;
    font-size: 0.85rem;
    font-weight: 400;
    letter-spacing: 0.03333em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.text-center{
	text-align: center;
}

div.container_one{
	>div{
		&:nth-child(1), &:nth-child(2), &:nth-child(3){
			display: inline-block;
			position: absolute;
			left: 0px;
			right: 0px;
			width: 100%;
			height: auto;
		}
		&:nth-child(1){
			top: 0px;
			bottom: auto;
			height: 100px;
			z-index: 2;
			div.col-12{
			    padding-left: 220px !important;
				>img{
    				display: inline-block;
    				position: absolute;
    				height: 60px;
    				left: 0;
    				top: 5px;
    				bottom: 0;
    				margin: auto 0;
				}
				button.menu{
					display: none;
				}
			}
		}
		&:nth-child(2){
			@include block_ctrl();
			height: 100%;
			padding-left: 0px;
			padding-right: 0px;
			padding-top: 100px;
			padding-bottom: 135px;
			z-index: 1;
		}
		&:nth-child(3){
			top: auto;
			bottom: 0px;
			height: 135px;
			z-index: 2;
		}
	}
}

ul.social_list{
	@include block_ctrl();
	float: right;
	width: auto;
   	list-style-type: none;
   	margin-block-start: 0px;
   	margin-block-end: 0px;
   	margin-inline-start: 0px;
   	margin-inline-end: 0px;
   	padding-inline-start: 0px;
	>li{
		@include block_ctrl();
		width: auto;
		margin: 5px;
		>a{
			>svg{
				@include ease(.25s);
				color: #8086e6;
			    font-size: 2rem;
			}
			&:hover{
				>svg{
					color: #FFFFFF;
				}
			}
		}
	}
}

div.content-step{
	span{
		@include block_ctrl();
		&.title_{
			font-size: 2.5rem;
			font-weight: 600;
			color: #FFFFFF;
			line-height: 1.2;
		}
		&.complement_{
			font-size: 1rem;
			font-weight: 500;
			color: $text_sec;
			line-height: 1.2;
		}
	}
	img.centered_img{
		@include block_ctrl();
    	width: 500px;
    	float: none;
    	max-width: 100%;
    	padding-bottom: 30px;
	}

}

div.steps{
	>div{
		position: relative;
		padding: 0px;
		>button.row{
			background-color: #192057;
			padding: 0px;
			border: none;
			@include ease(.25s);
			>div{
				text-align: center;
				>img{
					display: inline-block;
					position: relative;
					width: 75px;
				}
				>span{
					@include block_ctrl();
					margin-top: 5px;
					font-size: .9rem;
					font-weight: 700;
					color: #FFFFFF;
				}
			}

			&:hover{
				background-color: rgba(#192057, .9);
			}
			&.active{
				background-color: #48b636;
				pointer-events: none;
			}
		}
	}
}

div.container_two{
	>div.row{
		>div{
			&:nth-child(1){
				display: inline-block;
				position: absolute;
				left: 0px;
				right: 0px;
				top: 0px;
				width: 100%;
			    padding: 15px;
			    background-color: #f4f6f8;
			    z-index: 2;
				ul{
					@include block_ctrl();
					padding: 15px 0px;
    				float: right;
    				width: auto;
					>li{
						@include block_ctrl();
						width: auto;
						padding: 0px 15px;
						>a{
							color: #222c3e;
							font-weight: 600;
							@include ease(.25s);
							&:hover{
								color: rgba(#222c3e, .9);
							}
						}
					}
				}
			}
			&:nth-child(2){
	    		padding-top: 100px;
				span.tittle_{
					@include block_ctrl();
					color: #222c3e;
					font-size: 1.5rem;
					font-weight: 600;
					>span{
						font-size: 1.25rem;
						@include block_ctrl();
						color: #ababab;
					}
				}

			}
		}
	}
}

button.item_cat{
    flex: 0 0 105px;
    max-width: 105px;
    width: 105px;
    height: 105px;
    text-align: center;
    border: none;
    margin: 10px;
    padding: 0px;
    background-color: #192057;
    border-radius: 5px;
    @include ease(.25s);
    &:hover{
    	background-color: rgba(#192057, .75);
    }
	>img{
		display: inline-block;
		position: relative;
		width: 55px;
	}
	>span{
		@include block_ctrl();
		text-align: center;
		color: #FFFFFF;
    	line-height: 1.25;
    	font-size: .9rem;
	}
	&.active{
		background-color: #0096FF;
	}
}

div.item_invent{
	>div.row{
		>div{
			>span{
				display: none;
			}
			input.error{
				border:1px solid #f44336;
			}
		}
	}
}
div.titles_inventario{
	border-bottom: 1px solid #DDDDDD;
	>.row{
		>div{
			font-weight: 700;
		}
	}
}
span.error{
	color: #f44336;
}

div.item_info{
	>span{
		@include block_ctrl();
		&:nth-child(1){
    		font-weight: 600;
    		padding-bottom: 10px;
    		color: #1d1d1d;
		}
		&:nth-child(2){
			font-weight: 400;
    		padding-bottom: 12px;
    		margin-bottom: 12px;
    		position: relative;
    		color: #696969;
			&:after{
				content: '';
				display: inline-block;
				position: absolute;
				bottom: 0px;
				right: 0px;
				left: 0px;
				width: 100%;
				margin: 0px auto;
				height: 1px;
				background-color: #DDDDDD;
			}
		}
	}
}

.icon_rotate{
	svg{
    	-webkit-transform:rotate(180deg);
    	-moz-transform:rotate(180deg);
    	-ms-transform:rotate(180deg);
    	-o-transform:rotate(180deg);
    	transform:rotate(180deg);
	}
}

div.loaderContainer{
	@include absolute_center();
	background-color: rgba(#FFFFFF, .9);
	text-align: center;
	z-index: 5;
	span{
		@include block_ctrl();
		color: $primary;
	}
}

.btn_cs01{
    background-color: #192057;
    color: #FFFFFF;
    padding: 7px 20px;
    max-width: 175px;
    display: inline-block;
    position: relative;
    border-radius: 25px;
    line-height: 1.2;
    font-weight: 600;
    text-decoration: none;
    border: 1px solid #192057;
    @include ease(.25s);
    &:hover{
    	background-color: #FFFFFF;
    	color: #192057;
    }
}

table.MuiTable-root.custom_table{
	>thead.MuiTableHead-root{
		background-color: #DDDDDD;
		>tr.MuiTableRow-head{
			>th.MuiTableCell-root{
				border: 2px solid rgba(255, 255, 255, 1);
    			font-weight: 600;
    			font-size: .95rem;
			}
		}
	}
	>tbody.MuiTableBody-root{
		background-color: rgba($primary, .25);
		>tr.MuiTableRow-root{
			>td.MuiTableCell-root{
				border: 2px solid rgba(255, 255, 255, 1);
			}
		}
	}
}

.overflow_y{
	overflow-y: auto;
}

div.menu_content{
	display: none;
	position: fixed;
	z-index: 999;
	background-color: transparent;
	height: 100vh;
	width: 100vw;
	padding-left: 50px;
    right: -100vw;
    @include ease(.25s);
    &.show_{
    	right: 0vw;
    }
    >button{
    	background-color: rgba(255, 255, 255, .6);
    	border: none;
    	display: inline-block;
    	position: absolute;
    	left: 0px;
    	top: 0px;
    	width: 50px;
    	>svg{
    		color: $secondary;
    		font-size: 40px;
    	}
    }
	>div{
		@include block_ctrl();
		height: 100%;
		background-color: #FFFFFF;
		>div.row{
			margin: 0px;
			padding-bottom: 50px;
			pointer-events: none;
			>div{
				pointer-events: auto;
				padding: 15px;
				>ul{
					@include block_ctrl();
    				list-style-type: none;
    				margin-block-start: 0px;
    				margin-block-end: 0px;
    				margin-inline-start: 0px;
    				margin-inline-end: 0px;
    				padding-inline-start: 0px;
					>li{
						@include block_ctrl();
						padding: 15px 0px;
						text-align: center;
						>a{
							@include block_ctrl();
							@include ease(.25s);
							text-decoration: none;
							color: $primary;
							width: auto;
							float: none;
    						font-size: 1.25rem;
    						font-weight: 400;
							&:hover,&:active,&:focus{
								color: $secondary;
							}
						}
					}
				}
			}
		}
		>div.float_down{
			display: inline-block;
			position: absolute;
			bottom: 0px;
			left: 0px;
			right: 0px;
			width: 100%;
		}
		>div.float_top{
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			width: 100%;
			pointer-events: none;
			>img{
				@include block_ctrl();
				margin: 15px;
				float: left;
				width: 100px;
			}
		}
	}
}

button.custom_btn01{
	border-radius: 25px;
	padding: 8px 20px;
	>span.MuiButton-label{
		>span.MuiButton-startIcon,>span.MuiButton-endIcon{
			>svg{
				font-size: 32px;
			}
		}
	}
	&.prevBtn{
		>span.MuiButton-label{
			color: $primary;
			>span.MuiButton-startIcon{
				>svg{

				}
			}
		}
	}
	&.nextBtn{
		>span.MuiButton-label{
			>span.MuiButton-startIcon{
				>svg{

				}
			}
		}
	}
}

div.loading{
	@include absolute_center();
	background-color: rgba(244, 246, 248, .85);
	z-index: 999;
	&.blue{
		background-color: #3f46cf;
	}
}

.css-b62m3t-container>div{
	min-height: 56px !important;
}

@include media-breakpoint-down(xl) {
	div.content-step span.title_ {
	    font-size: 2rem;
	}
}

@include media-breakpoint-down(1500px) {
}

@include media-breakpoint-down(lg) {
	div.container_items_overflow{
	    overflow-x: auto;
		>div{
	    	width: 880px !important;
		}
	}
}
@include media-breakpoint-down(md) {
div.menu_content{
	display: inline-block;
}

ul.social_list{
	float: right;
	>li{
		margin: 5px 10px;
		>a{
			>svg{
			    font-size: 2.5rem;
			}
		}
	}
}

div.main_public_layout{
	padding: 0px;
	>div.row{
		margin: 0px;
		height: auto !important;
		>div.container_one{
			padding: 0px;
			height: auto !important;
			>div{
				&:nth-child(1), &:nth-child(2), &:nth-child(3){
					@include block_ctrl();
					z-index: 1;
				}
				&:nth-child(1){
				    height: auto;
					div.col-12{
					    padding-left: 220px !important;
						>img{
    						height: 50px;
						}
						>ul{
							display: none;
						}
						>button.menu{
							display: inline-block;
							position: relative;
							float: right;
    						padding: 0px;
    						margin: 32px 0px;
    						pointer-events: auto;
    						border: none;
    						background-color: transparent;
    						width: 28px;
							>span{
								display: inline-block;
								position: relative;
								float: left;
								width: 100%;
								height: 3px;
								background-color: #FFFFFF;
							    margin: 11px 0px;
								&:after,&:before{
									content: '';
									display: inline-block;
									position: absolute;
									width: 100%;
									right: 0px;
									bottom: 0px;
									height: 3px;
									background-color: #FFFFFF;
								}
								&:after{
									transform: translate(0px, 8px);
								}
								&:before{
									transform: translate(0px, -8px);
								}
							}
						}
					}
				}
				&:nth-child(2){
					height: auto;
					padding-left: 15px;
					padding-right: 15px;
					padding-top: 30px;
					padding-bottom: 30px;
					div.content-step{
						img.centered_img{
							height: 300px;
							max-width: 100%;
						}
					}
				}
				&:nth-child(3){
					height: 135px;
				}
			}
		}
		>div.container_two{
    		height: auto !important;
    		padding: 30px 15px;
			>div.row{
				margin: 0px;
				>div{
					&:nth-child(1){
						display: none;
					}
					&:nth-child(2){
						padding-top: 0px;
					}
				}
			}
		}
	}
}




	div.steps > div > button.row > div > img {
	    width: 60px;
	}
	div.steps > div > button.row > div > span {
	    font-size: .75rem;
	    font-weight: 600;
	}
	div.titles_inventario{
		display: none;
	}
	div.item_invent{
		>div.row{
			>div{
				position: relative;
				>span{
					display: inline-block;
					position: absolute;
				    font-weight: 600;
				    line-height: 1;
				    top: 0px;
				    bottom: 0px;
				    left: 0px;
				    margin: auto 0px;
				}
				>div{
					padding-left: 95px;
				}
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	div.main_public_layout > div.row > div.container_one > div:nth-child(2) div.content-step img.centered_img {
		display: none;
	}
	div.main_public_layout > div.row > div.container_one > div:nth-child(3) {
	    height: 95px;
	}
	div.steps > div > button.row > div > img {
	    width: 42px;
	}
	button.custom_btn01 {
	    border-radius: 25px;
	    padding: 8px 10px;
	    >span.MuiButton-label{
	    	font-size: .85rem;
			>span.MuiButton-startIcon,>span.MuiButton-endIcon{
				>svg{
					font-size: 26px;
				}
			}
			>span.MuiButton-startIcon{
				margin-right: 0px;
			}
			>span.MuiButton-endIcon{
			    margin-left: 0px;
			}
	    }
	}
}