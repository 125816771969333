.disabled{
	opacity: .75 !important;
	pointer-events: none !important;
}

@mixin block_ctrl() {
	display: inline-block;
	position: relative;
	float: left;
	width: 100%;
}
@mixin absolute_center() {
	display: inline-block;
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	top: 0px;
	margin: auto;
}
@mixin ease($time) {
	-webkit-transition: all $time ease;
	   -moz-transition: all $time ease;
	    -ms-transition: all $time ease;
	     -o-transition: all $time ease;
	        transition: all $time ease;
}
@mixin ease-in-out($time) {
	-webkit-transition: all $time ease-in-out;
	   -moz-transition: all $time ease-in-out;
	    -ms-transition: all $time ease-in-out;
	     -o-transition: all $time ease-in-out;
	        transition: all $time ease-in-out;
}

.enconde_sans{
	font-family: 'Encode Sans SC', sans-serif;
}

.block_ctrl{
	@include block_ctrl();
}
.btn{
	@include ease(.25s);
}
.fw_100{
	font-weight: 100;
}
.fw_200{
	font-weight: 200;
}
.fw_300{
	font-weight: 300;
}
.fw_400{
	font-weight: 400;
}
.fw_500{
	font-weight: 500;
}
.fw_600{
	font-weight: 600;
}
.fw_700{
	font-weight: 700;
}
.fw_800{
	font-weight: 800;
}
.fw_900{
	font-weight: 900;
}
